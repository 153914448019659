import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Settings } from "@/utils/settings";

async function getComponentByName(name: string, isVerB: boolean) {
    try {
        const componentModule = isVerB ? await import(`@/.ver_b/views/${name}/index.vue`) : await import(`@/views/${name}/index.vue`);
        return componentModule.default || componentModule;
    } catch (error) {
        return null;
    }
}

async function getComponent(routeName: string) {
    if (!Settings.isAbTestEnabledForComponent(routeName)) {
        return await getComponentByName(routeName, false);
    }

    let selectedView = sessionStorage.getItem("selectedView");
    if (!selectedView) {
        selectedView = Math.random() < 0.5 ? "A" : "B";
        sessionStorage.setItem("selectedView", selectedView);
    }

    const isVerB = selectedView === "B";
    return await getComponentByName(routeName, isVerB);
}

const routes: Array<RouteRecordRaw> = [
    {
        path: "/pay/:language/:orderKey",
        name: "Hosted Checkout Service - Pay",
        component: () => getComponent("Pay"),
        props: true,
    },
    {
        path: "/payment-methods/:language/:orderKey",
        name: "Hosted Checkout Service - Payment methods",
        component: () => getComponent("PaymentMethods"),
        props: true,
    },
    {
        path: "/onboarding",
        name: "Hosted Checkout Service - Onboarding",
        component: () => getComponent("Onboarding"),
    },
    {
        path: "/settings",
        name: "Hosted Checkout Service - Settings",
        component: () => getComponent("Settings"),
    },
    {
        path: "/error",
        name: "Hosted Checkout Service - Error",
        component: () => getComponent("Error"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Page Not Found",
        component: () => getComponent("NotFound"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, _, next) => {
    if (to.name) {
        document.title = to.name.toString();
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = to.name.toString();
        document.head.appendChild(meta);
    }
    next();
});

export default router;
