type Config = {
    settings: Settings;
    abconfig: AbConfig;
};

type Settings = {
    VUE_APP_API_URL: string;
    VUE_APP_ONBOARDING_URL: string;
    VUE_APP_NEW_RELIC_LICENSE_KEY: string;
    MATOMO_SITE_ID: number;
};

type AbConfig = {
    Pay: boolean;
    PaymentMethods: boolean;
    Onboarding: boolean;
    Settings: boolean;
    Error: boolean;
    NotFound: boolean;
};

type AbConfigKey = {
    [key: string]: boolean;
};

export const Settings = {
    config: {} as Config,

    init(newSettings: Settings, newAbConfig: AbConfig) {
        this.config = { settings: newSettings, abconfig: newAbConfig };
    },

    get() {
        return this.config.settings;
    },

    isAbTestEnabledForComponent(key: string): boolean {
        try {
            key = key.replace("View", "");
            return (this.config.abconfig as AbConfigKey)[key];
        } catch (error) {
            return false;
        }
    },
};
