import { defineCustomElements } from "@riverty/web-components/loader";
import { Telemetry } from "./utils/telemetry";
import { Settings } from "./utils/settings";
import { vMaska } from "maska/vue";
import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import VueMatomo from "vue-matomo";

const app = createApp(App);
defineCustomElements(window);
app.directive("maska", vMaska);

async function initializeApp() {
    try {
        const [settingsResponse, abconfigResponse] = await Promise.all([fetch("/settings.json"), fetch("/abconfig.json")]);
        const settings = await settingsResponse.json();
        const abconfig = await abconfigResponse.json();
        Settings.init(settings, abconfig);
        Telemetry.init();

        if (Settings.get().MATOMO_SITE_ID != 0) {
            app.use(VueMatomo, {
                host: "https://analytics.flow.riverty.com/",
                siteId: Settings.get().MATOMO_SITE_ID,
            });

            window._paq.push(["setExcludedQueryParams", ["\/.*\/"]]);
            window._paq.push(["setExcludedReferrers", ["prod.riverty.com"]]);
            window._paq.push(["disableCookies"]);
            window._paq.push(["trackPageView"]);
            window._paq.push(["enableLinkTracking"]);
        }

        app.use(router);
        app.mount("#app");
    } catch (error) {
        console.error("Failed to initialize app:", error);
    }
}

initializeApp();
